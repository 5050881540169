
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Container, Typography, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const EditBranch = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    entityId: ''
  });
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    // Fetch the branch data
    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/branches/${id}`, { signal })
      .then(response => {
        const branch = response.data;
        setFormData({
          name: branch.name,
          address: branch.address,
          entityId: branch.entityId
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Fetch branch request canceled:', error.message);
        } else {
          console.error('Error fetching branch:', error);
        }
      });

    // Fetch the entities data
    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/entities`, { signal })
      .then(response => setEntities(response.data))
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Fetch entities request canceled:', error.message);
        } else {
          console.error('Error fetching entities:', error);
        }
      });

    return () => {
      controller.abort(); // Cleanup: Cancel the requests if the component unmounts
    };
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleUpdateBranch = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/updateBranch/${id}`, formData);
      alert('Branch Updated!');
      navigate('/dashboard/branches');
    } catch (error) {
      alert('Branch Not Updated!');
      console.error('Error updating branch:', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" sx={{ mt: 2 }} gutterBottom>
        Edit Branch
      </Typography>
      <form onSubmit={handleUpdateBranch}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Branch Name"
              variant="outlined"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Address"
              variant="outlined"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Entity</InputLabel>
              <Select
                value={formData.entityId}
                onChange={handleChange}
                name="entityId"
                label="Entity"
                required
              >
                {entities.map((entity) => (
                  <MenuItem key={entity.id} value={entity.id}>
                    {entity.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Update Branch
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default EditBranch;
